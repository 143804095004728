import { defineStore } from 'pinia';

import type { CartItemType } from '~/api/cart';
import type {
  OrderDeliveryDataType,
  OrderInfoType,
  OrderOtherRecipientDataType,
  OrderPaymentDataType,
  OrderSummaryDataType,
} from '~/api/order';
import OrderAPI from '~/api/order';

const RESULT_DATA_VERSION = 1;
const RESULT_DATA_KEY = 'order-result-data';

export const useOrderStore = defineStore('order', () => {
  const t = useNuxtApp().$i18n.t;
  const modalStore = useModalStore();
  const productCartStore = useProductCartStore();
  const router = useRouter();

  const initialized = ref(false);
  const isReady = ref(false);
  const localCartData = ref<CartItemType[]>();
  const otherRecipient = ref<OrderOtherRecipientDataType>(null);
  const delivery = reactive<OrderDeliveryDataType>({
    addresses: [],
    variants: [],
    current: null,
  });
  const payment = reactive<OrderPaymentDataType>({
    isReady: false,
    current: null,
    variants: [],
  });
  const summary = reactive<OrderSummaryDataType>({
    basketPrice: 0,
    deliveryPrice: 0,
    discountPrice: 0,
    total: 0,
  });
  const blockKeys = reactive<Record<string, boolean>>({});
  const result = reactive({
    initialized: false,
    message: '',
    orderId: 0,
  });

  const cartData = computed(
    () =>
      localCartData.value ||
      productCartStore.cartData.filter((el) => el.isAvailable),
  );
  const isValid = computed(
    () =>
      cartData.value.length &&
      isReady.value &&
      !Object.keys(blockKeys).length &&
      payment.variants.some((el) => payment.current?.type === el.type),
  );

  const updateOrderState = (value: OrderInfoType) => {
    isReady.value = value.isReady;
    localCartData.value = value.basket.items;
    otherRecipient.value = value.user.otherReceiver;
    Object.assign(delivery, value.delivery);
    Object.assign(payment, value.payment);
    Object.assign(summary, value.summary);
  };

  const updateCartItem = (item: CartItemType, quantity: number) => {
    const localItem = localCartData.value?.find(
      (el) => el.skuId === item.skuId,
    );
    if (localItem) localItem.quantity = quantity;

    return productCartStore.updateCartItem({ ...item, quantity });
  };

  const deleteCartItem = (item: CartItemType) => {
    localCartData.value = localCartData.value?.filter(
      (el) => el.skuId !== item.skuId,
    );

    return productCartStore.updateCartItem({ ...item, quantity: 0 });
  };

  const fetchCurrentOrder = async () => {
    const data = await OrderAPI.current(null, {
      onError: (e) => {
        if (e.context?.response?.status === 403) {
          modalStore.showInfo({
            title: t('store.order.message.empty_error'),
            action: t('store.order.message.start'),
          });
          router.push('/catalog');
        } else {
          logError(e, 'Ошибка в оформлении заказа');
          modalStore.showError({
            title: getFetchErrorMessage(e, t('store.order.message.form_error')),
            action: t('store.order.message.action'),
          });
        }
      },
    });
    updateOrderState(data);

    nextTick(() => {
      initialized.value = true;
    });
  };

  const initializeSuccessfulData = () => {
    if (
      !result.initialized &&
      typeof localStorage !== 'undefined' &&
      localStorage[RESULT_DATA_KEY]
    ) {
      const data = JSON.parse(localStorage[RESULT_DATA_KEY]);
      if (data.version === RESULT_DATA_VERSION) {
        result.initialized = true;
        result.orderId = data.orderId;
        result.message = data.message;
      }
    }
  };

  const prepareSuccessfulData = (orderId: number, message: string) => {
    result.initialized = true;
    result.orderId = orderId;
    result.message = message;
  };

  const setBlockKey = (key: string) => (blockKeys[key] = true);

  const deleteBlockKey = (key: string) => delete blockKeys[key];

  const clear = () => {
    localCartData.value = undefined;
  };

  const clearCartData = () => {
    clear();
    productCartStore.clearCartData();
  };

  return {
    initialized,
    isValid,
    cartData,
    otherRecipient,
    delivery,
    payment,
    summary,
    updateOrderState,
    fetchCurrentOrder,
    updateCartItem,
    deleteCartItem,
    clearCartData,
    clear,

    setBlockKey,
    deleteBlockKey,

    result,
    initializeSuccessfulData,
    prepareSuccessfulData,
  };
});
