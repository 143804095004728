import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}