import type { NitroFetchOptions } from 'nitropack';

export const COUPON_DATA_VERSION = 1;
export const COUPON_DATA_STORAGE_KEY = 'coupon-data';

export const couponOptions: NitroFetchOptions<string> = {
  onRequest(context) {
    if (
      typeof localStorage !== 'undefined' &&
      localStorage[COUPON_DATA_STORAGE_KEY]
    ) {
      const cachedData = JSON.parse(localStorage[COUPON_DATA_STORAGE_KEY]);
      if (cachedData.version === COUPON_DATA_VERSION) {
        context.options.headers.set(cachedData.key, cachedData.code);
      }
    }
  },
};
