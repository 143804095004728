import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import { cartContentsResponseSchema } from '~/api/cart';
import { pickUpPointSchema } from '~/api/common';
import { couponOptions } from '~/api/plugins/coupon';
import { addressSchema, userSchema } from '~/api/user';
import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

const availableDeliveryTimeSchema = object()
  .shape({
    value: string().required(),
    isSelected: boolean().required(),
  })
  .required();

const availableDeliveryDateSchema = object()
  .shape({
    date: string().required(),
    isSelected: boolean().required(),
    timeIntervals: array().ensure().of(availableDeliveryTimeSchema).required(),
  })
  .required();

const orderDeliverySchema = object()
  .shape({
    addresses: array().ensure().of(addressSchema).required(),
    variants: array()
      .ensure()
      .of(
        object()
          .shape({
            name: string().required(),
            type: string().required(),
          })
          .required(),
      )
      .required(),
    current: object()
      .shape({
        addressId: number().nullable(),
        pvz: pickUpPointSchema.nullable(),
        availableDates: array().of(availableDeliveryDateSchema).nullable(),
        calculatedDeliveryDate: object()
          .shape({
            dateFrom: string().required(),
            dateTo: string().required(),
          })
          .required()
          .nullable(),
        selectedDatetime: string().nullable(),
        type: string().required(),
      })
      .nullable(),
  })
  .required();

const orderPaymentSchema = object()
  .shape({
    isReady: boolean().required(),
    current: object().shape({ type: string().required() }).nullable(),
    variants: array()
      .ensure()
      .of(
        object()
          .shape({
            name: string().required(),
            type: string().required(),
          })
          .required(),
      )
      .required(),
  })
  .required();

const orderSummarySchema = object()
  .shape({
    basketPrice: number().required(),
    deliveryPrice: number().required(),
    discountPrice: number().required(),
    total: number().required(),
  })
  .required();

const orderOtherReceiverSchema = object()
  .shape({
    lastName: string().required(),
    name: string().required(),
    phone: string().required(),
  })
  .required()
  .nullable();

const currentOrderResponseSchema = object()
  .shape({
    isReady: boolean().required(),
    basket: cartContentsResponseSchema,
    delivery: orderDeliverySchema,
    summary: orderSummarySchema,
    payment: orderPaymentSchema,
    user: userSchema.shape({ otherReceiver: orderOtherReceiverSchema }),
  })
  .required();

const activateOrderResponseSchema = object()
  .shape({
    id: number().required(),
    message: string().required(),
  })
  .required();

const setRecipientBodySchema = object()
  .shape({
    name: string().required(),
    lastName: string().required(),
    phone: string().required(),
  })
  .required();

const selectDeliveryBodySchema = object()
  .shape({
    type: string().required(),
    pvzId: number().optional(),
    addressId: number().optional(),
  })
  .required();

const selectDeliveryDateTimeBodySchema = object()
  .shape({
    date: string().required(),
    timeInterval: string().required(),
  })
  .required();

const couponResponseSchema = object()
  .shape({ name: string().required(), value: string().required() })
  .required();

const couponBodySchema = object()
  .shape({ coupon: string().required() })
  .required();

const setPaymentBodySchema = object()
  .shape({ type: string().required() })
  .required();

const getPaymentTokenParamsSchema = object()
  .shape({ orderId: number().required().positive() })
  .required();

const getPaymentTokenResponseSchema = object()
  .shape({ token: string().required() })
  .required();

export type OrderInfoType = InferType<typeof currentOrderResponseSchema>;

export type OrderOtherRecipientDataType = InferType<
  typeof orderOtherReceiverSchema
>;

export type OrderDeliveryDataType = InferType<typeof orderDeliverySchema>;

export type OrderPaymentDataType = InferType<typeof orderPaymentSchema>;

export type OrderSummaryDataType = InferType<typeof orderSummarySchema>;

export type AvailableDeliveryTimeType = InferType<
  typeof availableDeliveryTimeSchema
>;

export type AvailableDeliveryDateType = InferType<
  typeof availableDeliveryDateSchema
>;

const OrderAPI = {
  current: request.prepare({
    url: 'orders/current',
    schemas: { response: currentOrderResponseSchema },
    options: [
      couponOptions,
      { methodKey: 'order:current', selfInterrupted: true },
    ],
  }),
  activate: request.prepare({
    url: 'orders/current',
    method: HTTPMethod.post,
    schemas: { response: activateOrderResponseSchema },
    options: couponOptions,
  }),
  setRecipient: request.prepare({
    url: 'orders/current/receiver',
    method: HTTPMethod.post,
    schemas: {
      body: setRecipientBodySchema,
      response: currentOrderResponseSchema,
    },
    options: couponOptions,
  }),
  deleteRecipient: request.prepare({
    url: 'orders/current/receiver/clear',
    schemas: { response: currentOrderResponseSchema },
    options: couponOptions,
  }),
  selectDelivery: request.prepare({
    url: 'orders/current/delivery',
    method: HTTPMethod.post,
    schemas: {
      body: selectDeliveryBodySchema,
      response: currentOrderResponseSchema,
    },
    options: [
      couponOptions,
      { methodKey: 'order:selectDelivery', selfInterrupted: true },
    ],
  }),
  selectDeliveryDateTime: request.prepare({
    url: 'orders/date',
    method: HTTPMethod.post,
    schemas: {
      body: selectDeliveryDateTimeBodySchema,
      response: currentOrderResponseSchema,
    },
    options: couponOptions,
  }),
  setCoupon: request.prepare({
    url: 'orders/current/coupon',
    method: HTTPMethod.post,
    schemas: { body: couponBodySchema, response: couponResponseSchema },
    options: couponOptions,
  }),
  deleteCoupon: request.prepare({
    url: 'orders/current/coupon',
    method: HTTPMethod.delete,
    schemas: { body: couponBodySchema, response: couponResponseSchema },
    options: couponOptions,
  }),
  setPayment: request.prepare({
    url: 'orders/current/payment',
    method: HTTPMethod.post,
    schemas: {
      body: setPaymentBodySchema,
      response: currentOrderResponseSchema,
    },
    options: couponOptions,
  }),
  getPaymentToken: request.prepare({
    url: ({ orderId }) => `orders/${orderId}/payment/token`,
    schemas: {
      params: getPaymentTokenParamsSchema,
      response: getPaymentTokenResponseSchema,
    },
    options: couponOptions,
  }),
};

export default OrderAPI;
