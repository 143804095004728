<script lang="ts" setup>
defineProps<{
  dark?: boolean;
  count?: number;
}>();
</script>

<template>
  <BaseFixedButton :dark="dark" class="cartButton">
    <SvgoSimpleCart filled class="cartButton__icon" />
    <Transition name="fade">
      <span v-if="count" class="cartButton__count">
        {{ count }}
      </span>
    </Transition>
  </BaseFixedButton>
</template>

<style lang="scss">
.cartButton {
  & &__icon {
    height: 40px;
    width: 32px;
    transition: opacity 0.3s ease;
    position: absolute;
    display: block;
    color: #fff;
    margin-bottom: 0;
    transform: translateY(-5%);
  }

  & &__count {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 19px;
    height: 19px;
    right: 35%;
    top: 40%;
    transform: translate(50%, -50%);

    background: #121212;
    border-radius: 35px;
    color: #fff;
    font-style: normal;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
  }

  @include mq('sm') {
    & &__icon {
      height: 32px;
      width: 24px;
    }

    & &__count {
      width: 14px;
      height: 14px;
      font-size: 10px;
    }
  }
}
</style>
