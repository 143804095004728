import type { RouterOptions } from '@nuxt/schema';

export default <RouterOptions>{
  scrollBehaviorType: 'smooth',
  scrollBehavior: (_to, _from, savedPosition) => {
    if (savedPosition) return savedPosition;

    const customScroll = document.querySelector('.global-scroll-viewport');

    if (customScroll)
      customScroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    else window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    return {};
  },
};
