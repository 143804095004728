import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

export const CART_REQUEST_ORDER_KEY = 'cart-request';

const cartItemSchema = object().shape({
  productId: number().required(),
  skuId: number().required(),
  name: string().required(),
  code: string().nullable(),
  price: number().required(),
  basePrice: number().nullable(),
  isAvailable: boolean().required(),
  isGift: boolean().optional(),
  quantity: number().required(),
});

export const cartContentsResponseSchema = object().shape({
  items: array().ensure().of(cartItemSchema).required(),
});

const updateCartItemBodySchema = object().shape({
  id: number().required(),
  quantity: number().required(),
});

const deleteCartItemBodySchema = object().shape({ id: number().required() });

const transferCartBodySchema = array()
  .ensure()
  .of(updateCartItemBodySchema)
  .required();

export type CartItemType = InferType<typeof cartItemSchema>;

const CartAPI = {
  contents: request.prepare({
    url: 'basket',
    schemas: { response: cartContentsResponseSchema },
  }),
  updateItem: request.prepare({
    method: HTTPMethod.put,
    url: 'basket',
    schemas: {
      response: cartContentsResponseSchema,
      body: updateCartItemBodySchema,
    },
    options: {
      orderKey: CART_REQUEST_ORDER_KEY,
      methodKey: 'order:updateItem',
      selfInterrupted: ({ body }) => `product-${body.id}`,
    },
  }),
  removeItem: request.prepare({
    method: HTTPMethod.delete,
    url: 'basket',
    schemas: {
      response: cartContentsResponseSchema,
      body: deleteCartItemBodySchema,
    },
    options: { orderKey: CART_REQUEST_ORDER_KEY },
  }),
  transfer: request.prepare({
    method: HTTPMethod.post,
    url: 'basket/transfer',
    schemas: {
      response: cartContentsResponseSchema,
      body: transferCartBodySchema,
    },
  }),
  synchronize: request.prepare({
    method: HTTPMethod.post,
    url: 'basket/synchronize',
    schemas: {
      response: cartContentsResponseSchema,
      body: transferCartBodySchema,
    },
    options: {
      methodKey: 'basket:synchronize',
      selfInterrupted: true,
    },
  }),
};

export default CartAPI;
