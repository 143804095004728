export default () => {
  const config = useRuntimeConfig();
  const nonce = useNonce();

  useHead(
    config.public.siteEnvironment === 'prod'
      ? {
          script: [
            {
              tagPosition: 'head',
              type: 'text/javascript',
              innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;j.setAttribute('nonce','${nonce}');f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PT2S3Q6');`,
            },
          ],
          noscript: [
            {
              tagPosition: 'bodyClose',
              innerHTML:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PT2S3Q6" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
            },
          ],
        }
      : {
          script: [
            {
              tagPosition: 'head',
              type: 'text/javascript',
              innerHTML: 'var dataLayer = [];',
            },
          ],
        },
    // TODO: deprecated настройка
    { mode: 'server' },
  );
};
