import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

import AuthAPI from '~/api/auth';
import type { ProfileDataType, UserType } from '~/api/user';
import UserAPI, { OrderStatus } from '~/api/user';
import type { ResponseError } from '~/api/utils/types';

export const useUserStore = defineStore('user', () => {
  const router = useRouter();
  const productCartStore = useProductCartStore();
  const subscribeProductsStore = useSubscribeProductsStore();
  const orderStore = useOrderStore();
  const tokenStore = useTokenStore();

  const isLoading = ref(false);
  const user = ref<UserType>();
  const profile = ref<ProfileDataType>({
    addresses: [],
    orders: [],
    productOrderedIds: [],
    subscriptions: [],
  });

  const authorized = computed(() => !!tokenStore.value);

  const logout = async () => {
    try {
      await AuthAPI.logout();
    } finally {
      tokenStore.value = null;
      user.value = undefined;
      Sentry.setUser(null);
      productCartStore.clearCartData();
      subscribeProductsStore.clear();
      orderStore.clear();
    }
  };

  const updateUser = (data: UserType) => {
    user.value = data;
    if (import.meta.client)
      Sentry.setUser({
        id: data.phone || undefined,
        email: data.email || undefined,
        username: data.lastName || undefined,
      });
  };

  const fetchUser = async () => {
    if (!authorized.value || isLoading.value) return;
    isLoading.value = true;

    try {
      const { user: userData, ...profileData } = await UserAPI.details();
      updateUser(userData);
      profile.value = profileData;
    } catch (e) {
      if ((e as ResponseError)?.context?.response?.status === 401) {
        await logout();
      }
    }

    isLoading.value = false;
  };

  const updatePhoneConfirmed = (value: boolean) => {
    if (user.value) updateUser({ ...user.value, isPhoneConfirmed: value });
  };

  const toProfile = () => router.push(authorized.value ? '/profile' : '/auth');

  const onOrderPaid = (orderId: number) => {
    const order = profile.value.orders.find((item) => item.id === orderId);
    if (order) {
      order.isPaid = true;
      order.status = OrderStatus.pending;
    }
  };

  const updateOrders = (data: ProfileDataType['orders']) => {
    profile.value.orders = data;
  };

  watch(authorized, () => {
    if (import.meta.client && authorized.value) fetchUser();
  });

  return {
    authorized,
    user,
    profile,
    fetchUser,
    updateUser,
    updatePhoneConfirmed,
    logout,
    toProfile,
    onOrderPaid,
    updateOrders,
  };
});
