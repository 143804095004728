import payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_6SF5wI2nfu3QXQ9ursmuD3nJgV5x8kuZZrZJXmFrEEA from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_oGIOGGUSeSs3r4PqG4Y15k1B5OR0Z_5cnVs9fgyPmeI from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yHzNZHtnn1wEGvR1k5i2hCgevRJeh9SojSNq0MhXu4Q from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_tRHEMHB_1OjSQ3086W3620VS8b_qmf64DlrhB3xS4z8 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Eo7WO3rFiA2XuvS25u7ryL79uL__sJ0_SM_mzxysYS0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_llQ86YY3tALZ97z5MYWzyS4XQUC6MJ9JSo5JojMybic from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aJBMhy7p5kAQwShmX02vznST0pf5FLMqDMbJ8oh7O50 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_BT4yqdUNU8yPuAJLalbeedGCotQ7XW2aQFIGkX5DPlM from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_yhI3N80ObqLObvRg04F_mqJj07tJ72j7LDWkl1_sNZQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_IX4myDIFVT2AwqwkrZg7F0v_nxXBw7UQgJSzpXSTdYU from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_vjpgpIFiW2rCJtD79FwGkUd0Fwc6KXYHEffqteXEZmw from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_better-sqlite3@11.9.1_db0@0.3.1_b_cbfadfc33963714c7bce40a317014b80/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_3UpAgUw_HK2N1kE4P3Zh0nDg8OhDef_XcpZxdXWckLI from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/app/.nuxt/pwa-icons-plugin.ts";
import plugin_nKvso0AA3oZqMKqLdV1r_v6xy8P5JDAs5gh8cP0gxFw from "/app/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/plugin.js";
import autosize_xhzljyesIQFkoU_YAcquUaPqh4pFxOCMjmcMJsp_1oo from "/app/plugins/autosize.ts";
import request_9gCQbAiWGlAy9fUYAs4zVRYnxG1YLLfpylHRuF0fPxs from "/app/plugins/request.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import ssg_detect_xFGCNA__tlyzr9Fz6U1D3WQZ_xuakXuOIGmWwib_zZA from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.3_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_65a5e8dd996f24ebceb6889bd1aa97bd/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import pwa_client_CmthOkw1RntPj8Qe5XaYk5VbATLtMK0Heu3OVP92PX8 from "/app/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_sass-embe_064265ea0d9535f3a60c699b6ae73bc9/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw,
  revive_payload_client_6SF5wI2nfu3QXQ9ursmuD3nJgV5x8kuZZrZJXmFrEEA,
  unhead_oGIOGGUSeSs3r4PqG4Y15k1B5OR0Z_5cnVs9fgyPmeI,
  router_yHzNZHtnn1wEGvR1k5i2hCgevRJeh9SojSNq0MhXu4Q,
  _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM,
  payload_client_tRHEMHB_1OjSQ3086W3620VS8b_qmf64DlrhB3xS4z8,
  navigation_repaint_client_Eo7WO3rFiA2XuvS25u7ryL79uL__sJ0_SM_mzxysYS0,
  check_outdated_build_client_llQ86YY3tALZ97z5MYWzyS4XQUC6MJ9JSo5JojMybic,
  chunk_reload_client_aJBMhy7p5kAQwShmX02vznST0pf5FLMqDMbJ8oh7O50,
  switch_locale_path_ssr_BT4yqdUNU8yPuAJLalbeedGCotQ7XW2aQFIGkX5DPlM,
  route_locale_detect_yhI3N80ObqLObvRg04F_mqJj07tJ72j7LDWkl1_sNZQ,
  i18n_IX4myDIFVT2AwqwkrZg7F0v_nxXBw7UQgJSzpXSTdYU,
  plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_vjpgpIFiW2rCJtD79FwGkUd0Fwc6KXYHEffqteXEZmw,
  i18n_3UpAgUw_HK2N1kE4P3Zh0nDg8OhDef_XcpZxdXWckLI,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  plugin_nKvso0AA3oZqMKqLdV1r_v6xy8P5JDAs5gh8cP0gxFw,
  autosize_xhzljyesIQFkoU_YAcquUaPqh4pFxOCMjmcMJsp_1oo,
  request_9gCQbAiWGlAy9fUYAs4zVRYnxG1YLLfpylHRuF0fPxs,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  ssg_detect_xFGCNA__tlyzr9Fz6U1D3WQZ_xuakXuOIGmWwib_zZA,
  pwa_client_CmthOkw1RntPj8Qe5XaYk5VbATLtMK0Heu3OVP92PX8
]