<script lang="ts" setup>
defineProps<{
  disabled: boolean;
  viewportClass?: string;
}>();
defineSlots<{
  default: () => VNode;
}>();
</script>

<template>
  <ScrollAreaRoot type="auto" class="baseScroll">
    <ScrollAreaViewport class="baseScroll__viewport" :class="viewportClass">
      <slot />
    </ScrollAreaViewport>
    <ScrollAreaScrollbar
      v-if="!disabled"
      orientation="vertical"
      class="baseScroll__scrollbar"
    >
      <ScrollAreaThumb class="baseScroll__thumb" />
    </ScrollAreaScrollbar>
  </ScrollAreaRoot>
</template>

<style lang="scss">
.baseScroll {
  &__viewport {
    height: 100%;
  }

  &__scrollbar {
    width: 4px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &__thumb {
    border-radius: 3px;
    background-color: #121212;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 8px;
    }
  }
}
</style>
