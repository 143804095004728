const TOKEN_KEY = 'authorization_token';

export const useTokenStore = defineStore('token', () => {
  const config = useRuntimeConfig();

  const value = useCookie(TOKEN_KEY, {
    path: '/',
    maxAge: 365 * 24 * 60 * 60,
    sameSite: 'lax',
    secure: config.public.siteEnvironment !== 'dev',
  });

  return { value };
});
